import {Navigate, Outlet} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "./AuthContext";

const PrivateRoutes = () => {
    const authContext = useContext(AuthContext)
    console.log("rendering private routes: ", authContext?.userData?.id);
    if (authContext?.isLoading){
        return <></>
    }
    if (!authContext?.userData?.id) {
        return <Navigate to="/signIn" />
    }
    return(
        <Outlet/>
    )
}

export default PrivateRoutes
