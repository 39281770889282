import userPool from "./UserPool";
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserSession,
    ISignUpResult
} from "amazon-cognito-identity-js";

export interface IUserData {
    id: string | undefined
    name: string | undefined
    email: string | undefined
    verified: boolean | undefined
}
export function signUp(username: string, email:string, password:string): Promise<ISignUpResult|undefined> {
    return new Promise((resolve, reject) => {
        const attributeList = [];
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: 'name',
                Value: username,
            }),
        );
        userPool.signUp(email, password, attributeList, [],
            (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(result);
            }
        );
    });
}

export function confirmSignUp(email: string, code: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                return reject(err);
            }
            resolve(true);
        });
    });
}

export function signIn(username: string, password: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}

export function signOut() {
    const cognitoUser = userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
        if (!cognitoUser) {
            return reject(new Error("No user found"))
        }
        cognitoUser.signOut(()=>{
            resolve(undefined);
        });
    });
}

export function getSession(): Promise<CognitoUserSession|null> {
    const cognitoUser = userPool.getCurrentUser()
    return new Promise((resolve, reject) => {
        if (!cognitoUser) {
            return reject(new Error("No user found"))
        }
        cognitoUser.getSession((err:Error, session: CognitoUserSession|null) => {
            if (err) {
                return reject(err)
            }
            resolve(session)
        })
    })
}
export async function getCurrentUser(): Promise<IUserData> {
    return new Promise((resolve, reject) => {

        const cognitoUser: CognitoUser | null = userPool.getCurrentUser();

        if (!cognitoUser) {
            reject(new Error("No user found"));
            return;
        }

        cognitoUser.getSession((err:Error|null, session: CognitoUserSession|null) => {
            if (err) {
                reject(err);
                return;
            }
            cognitoUser.getUserAttributes((err:Error|undefined, attributes: CognitoUserAttribute[] | undefined) => {
                if (err) {
                    reject(err);
                    return;
                }
                const userData: IUserData = {
                    id: cognitoUser.getUsername(),
                    name: getValue(attributes, "name"),
                    email: getValue(attributes, "email"),
                    verified: getValue(attributes, "email_verified") === "true",
                }
                resolve(userData);
                function getValue(cognitoAttributes: CognitoUserAttribute[]|undefined, key: string): string|undefined {
                    return cognitoAttributes?.find((attributeKey) => attributeKey.Name === key)?.getValue()
                }
            });
        });
    });
}

export async function forgotPassword(username:string) {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        })

        cognitoUser.forgotPassword({
            onSuccess: () => {
                resolve(null)
            },
            onFailure: (err) => {
                reject(err)
            },
        })
    })
}

export function confirmPassword(username: string, confirmationCode: string, newPassword: string) {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        })

        cognitoUser.confirmPassword(confirmationCode, newPassword, {
            onSuccess: () => {
                resolve(null)
            },
            onFailure: (err) => {
                reject(err)
            },
        })
    })
}



