import {FormEvent, useState} from "react"
import { forgotPassword } from "./AuthService"
import { Link } from "react-router-dom"
import {Alert, Container, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function ForgotPassword() {
    const [username, setUsername] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setError("")

        try {
            await forgotPassword(username)
            setSuccess(true)
        } catch (err: any) {
            setError(err?.message || "unknown error")
            setTimeout(() => {
                // After 3 seconds set the show value to false
                setError("");
            }, 4000)
        }
    }

    if (success) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <Container className={"col-6 col-lg-3 col-md-4"}>
                    <h2>Forgot Password</h2>
                    <p>
                        Check your email for the confirmation code to reset your password.
                        After that go to the follow link:
                    </p>
                    <Link to="/resetPassword">Reset Password</Link>
                </Container>

            </div>
        )
    }

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <Container className={"col-6 col-lg-3 col-md-4"}>
                <h2>Forgot Password</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formSignUpEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            required placeholder="one@mail.com"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <Form.Text>
                            <Link to="/SignIn">SignIn</Link>
                        </Form.Text>
                    </Form.Group>
                    <Button type="submit" variant="outline-primary">Submit</Button>
                </Form>

                {error && <Alert className={"mt-4"} variant={"warning"}>{error}</Alert>}
            </Container>
        </div>
    )
}