
import {useEffect, useState} from "react";
import {IProject} from "../../../models/Project";
import {getAllProjects} from "../../../services/project-service";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";


export function AllProjectsPage(){
    const navigate = useNavigate();
    const [projects, setProjects] = useState<IProject[]>()
    useEffect(()=>{
        (async ()=>{
            const fetchedProjects = await getAllProjects();
            setProjects(fetchedProjects)
        })().catch(console.error)
    },[]);
    function handleClick(path: string) {
        navigate(path);
    }
    const projectItems = projects?.map(project =>
        <li key={project.id}>
            {project.title}
        </li>
    );
    return(
        <div>
            <h1>Projects</h1>
            <Button variant="outline-primary" onClick={()=>handleClick("/new-private-project-creation")}>create new project</Button>
            <ul>
                <li>project 1</li>
                <li>project 2</li>
                <li>project 3</li>
                {projectItems}
            </ul>
        </div>
    );
}