export enum HttpRequestMethod{
    GET="GET",
    POST="POST"
}
export const  apiRequest = async <T> (
    url: string,
    options: {
        method?: HttpRequestMethod,
        token?: string,
        headers?: { [key:string]: string }
        data?: unknown
    }
): Promise<T> =>{
    const httpMethod = options.method || HttpRequestMethod.GET;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${options?.token}`,
        ...options?.headers
    }
    const response = await fetch(url, {
        method: httpMethod,
        body: JSON.stringify(options.data),
        headers
    });
    if (response.ok){

        let data = null;
        try {
            data = await response.json();
        } catch (error) {
            console.error("Error during parsing response body to JSON")
        }
        return data;

    } else if(response.status === 401) {
        // window.location.href = "/login"
    } else if(response.status === 403) {
        // reload ?
    } else if(response.status === 500) {
        // ask to send data fro developers || notify developers
    }
    const error = await response.json();
    return Promise.reject(error);
}