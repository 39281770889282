import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes} from "react-router-dom";
import {NotFoundPage} from "../pages/not-found-page";
import {AllProjectsPage} from "../pages/project/all-projects-page";
import {PublicLandingPage} from "../pages/landing/public-landing-page";

import SignUp from "../../auth/SignUp";
import SignIn from "../../auth/SignIn";
import {UserProfilePage} from "../pages/user/user-profile-page";
import PrivateRoutes from "../../auth/PrivateRoutes";
import {TopBar} from "../shared/TopBar/TopBar";
import ForgotPassword from "../../auth/ForgotPassword";
import ResetPassword from "../../auth/ResetPassword";
import {NewPrivateProjectGuid} from "../pages/project/new-private-project-guid";

function App() {
  return (
    <div>
        <Routes>
            <Route path="/" element={<PublicLandingPage/>}/>
            <Route element={<PrivateRoutes/>}>
                <Route element={<TopBar/>}>
                    <Route path="/profile" element={<UserProfilePage/>}/>
                    <Route path="/projects" element={<AllProjectsPage/>}/>
                    <Route path="/new-private-project-creation" element={<NewPrivateProjectGuid/>}/>
                </Route>
            </Route>
            <Route path="/signup" element={<SignUp/>}/>
            <Route path="/signin" element={<SignIn/>}/>
            <Route path="/forgotPassword" element={<ForgotPassword/>}/>
            <Route path="/resetPassword" element={<ResetPassword/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    </div>
  );
}

export default App;
