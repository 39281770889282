import "./public-landing-page.css"
import {Link} from "react-router-dom";

export function PublicLandingPage(){
    return(
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="title">
                <h1>ClearDomain</h1>
                <p>Manage knowledge. Write future</p>
                <Link className={"mt-5"}  to={"/SignIn"}> sign in </Link>
            </div>
            <div className={"footer"}>
                <p>info@ClearDomain.co.uk</p>
            </div>
        </div>
    );
}