import {useContext} from "react";
import Button from "react-bootstrap/Button";
import {AuthContext} from "../../../auth/AuthContext";
import {useNavigate} from "react-router-dom";

export function UserProfilePage(){
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    async function getOnClickSignOutAndGoToSignIn() {
        await authContext?.signOut();
        navigate("/signIn")
    }
    return(
        <div>
            <h1>User Profile</h1>
            {
                authContext?.userData?.id && (
                    <ul>
                        <li>id {authContext?.userData?.id} </li>
                        <li>name {authContext?.userData?.name} </li>
                        <li>email {authContext?.userData?.email} </li>
                    </ul>
                )
            }
            <Button onClick={getOnClickSignOutAndGoToSignIn} variant="outline-primary">Sign Out</Button>
        </div>
    );
}
