import {FormEvent, useState} from 'react';
import {confirmSignUp, signUp} from "./AuthService";

import Button from 'react-bootstrap/Button';
import {Alert, Container, Form} from "react-bootstrap";
import {getErrorMessage} from "../components/shared/getErrorMessage";
import {Link, useNavigate} from "react-router-dom";

export function SignUp() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailConfirmationCode, setEmailConfirmationCode] = useState('');

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState("")

    function getRidOfErrorMessageInSeveralSeconds() {
        setTimeout(() => {
            // After 3 seconds set the show value to false
            setError("");
        }, 4000)
    }

    const onInitialUserDataSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            setError("");
            const signUpResult = await signUp(username, email, password);
            console.log(signUpResult);
            // alert('User Added Successfully');
            setSuccess(true);
        } catch (e) {
            console.log(e)
            // alert("Couldn't sign up");
            setSuccess(false);
            setError(getErrorMessage(e))
            getRidOfErrorMessageInSeveralSeconds();
        }
    };
    const onEmailConfirmationCodeSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await confirmSignUp(email, emailConfirmationCode);
            navigate("/signIn")
        } catch (e) {
            setError(getErrorMessage(e))
            getRidOfErrorMessageInSeveralSeconds();
        }
    }

    if (success) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <Container className={"col-6 col-lg-3 col-md-4"}>
                    <h2>Signup successful!</h2>
                    <p>Please check your email for the confirmation code.</p>
                    <Form onSubmit={onEmailConfirmationCodeSubmit}>
                        <Form.Group className="mb-3" controlId="formCodeForEmailConfirmation">
                            <Form.Label>Confirmation code</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={emailConfirmationCode.toLowerCase().trim()}
                                onChange={(e) => setEmailConfirmationCode(e.target.value)}
                            />
                        </Form.Group>
                        <Button type="submit" variant="outline-primary">confirm email</Button>
                    </Form>
                    {error && <Alert className={"mt-4"} variant={"warning"}>{error}</Alert>}
                </Container>
            </div>
        )
    } else {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <Container className={"col-6 col-lg-3 col-md-4"}>
                    <h2>SignUp</h2>
                    <Form onSubmit={onInitialUserDataSubmit}>
                        <Form.Group className="mb-3" controlId="formSignUpUserName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                required placeholder="Jone Dow"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formSignUpEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                required placeholder="one@mail.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formSignUpPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Form.Text>
                                <Link to="/SignIn">SignIn</Link>
                            </Form.Text>
                        </Form.Group>
                        <Button type="submit" variant="outline-primary">Sign Up</Button>
                    </Form>
                    {error && <Alert className={"mt-4"} variant={"warning"}>{error}</Alert>}
                </Container>
            </div>
        );
    }
}

export default SignUp;