import {createContext, FC, ReactNode, useEffect, useState} from "react";
import * as authService from "./AuthService"
import {IUserData, signIn, signOut} from "./AuthService";
import {CognitoUserSession} from "amazon-cognito-identity-js";

const noUserData: IUserData = {
    email: undefined,
    id: undefined,
    name: undefined,
    verified: undefined
}
export interface IAuthContext {
    userData: IUserData | undefined,
    signIn: (username:string, password: string) => Promise<CognitoUserSession>,
    signOut: () => Promise<void>,
    isLoading: boolean
}

const AuthContext = createContext<IAuthContext|null>(null);

const AuthProvider: FC<{children: ReactNode}> = ({ children }) => {
    const [user, setUser] = useState<IUserData>(noUserData);
    const [isLoading, setIsLoading] = useState(true);

    const getCurrentUser = async () => {
        try {
            const user = await authService.getCurrentUser();
            console.log("set current user", user);
            setUser(user);
        } catch (err) {
            // not logged in
            console.log(err);
            setUser(noUserData);
        }
    };

    useEffect(() => {
        (async ()=>{
            getCurrentUser()
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        })();
    }, []);
    const signInContextFunction = async (username: string, password: string) => {
        const session = await signIn(username, password);
        await getCurrentUser();
        return session;
    };
    const signOutContextFunction = async () => {
        await signOut();
        setUser(noUserData);
    };
    const authData: IAuthContext = {
        userData: user,
        signIn: signInContextFunction,
        signOut: signOutContextFunction,
        isLoading
    }
    return (
        <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext };

