import "./TopBar.css"
import {Outlet, useNavigate} from "react-router-dom";
import {Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useContext} from "react";
import {AuthContext} from "../../../auth/AuthContext";

export function TopBar(props: any) {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    function handleClick(path: string) {
        navigate(path);
    }
    return (
        <div>
            <div className="header">
                <div className="left-container">
                    <Stack direction="horizontal" gap={3}>
                        <Button variant="outline-light" onClick={()=>handleClick("/projects")}>
                            ClearDomain.co.uk
                        </Button>
                    </Stack>
                </div>
                <div className="middle-container">
                    <Stack direction="horizontal" gap={2}>

                    </Stack>

                </div>
                <div className="right-container">
                    <Stack direction="horizontal" gap={2}>
                        <Button variant="outline-light" onClick={()=>handleClick("/profile")}>
                            { authContext?.userData?.name || "account"}
                        </Button>
                    </Stack>
                </div>
            </div>
            <Outlet/>
        </div>

    );
}
